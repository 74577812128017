<template>
  <v-card>
    <v-stepper v-model="currentStep" vertical height="auto">
      <v-stepper-step :complete="currentStep > 0" step="0">
        {{ $t('before_we_begin') }}
      </v-stepper-step>

      <v-stepper-content step="0">
        <p v-html="$t('before_we_begin_description')"></p>

        <p>{{ $t('by_clicking') }} <span class="font-weight-bold">{{ $t('continue') }}</span>, {{ $t('read_and_agree')
        }} <a href="https://insightzclub.com/privacy-policy-mobile/">{{ $t('privacy_policy') }}</a>
          {{ $t('rules_mentioned') }}.</p>
        <v-btn color="primary" @click="currentStep = 1">{{ $t('continue') }}</v-btn>
      </v-stepper-content>


      <v-stepper-step step="1">
        {{ $t('login_information') }}
      </v-stepper-step>

      <v-stepper-content step="1">
        <v-card height="500px">
        <v-alert :value="true" type="error" v-if="errorMessage.length > 0">{{ errorMessage }}</v-alert>
        <v-form ref="step1">
          <p>{{ $t('provide_login_info') }}</p>

          <v-text-field 
            v-model="user.name" 
            :label="$t('name')"
             required 
             clearable
            :rules="[value => !!value || 'Name required.']" 
          />
          <v-layout row wrap>
            <v-col cols="4">
              <v-autocomplete
                v-model="country.answer"
                :items="country.options"
                :label="country.title"
                :item-text="(item) =>`${item.title}  (${item.code})`"
                item-value="title"
                :rules="[ value => (value != null && value != undefined) || country.required_message ]"
              />
            </v-col>

            <v-col cols="8">
              <v-text-field 
                type="number" 
                v-model="user.mobileNumber" 
                :label="$t('mobile_number')" 
                clearable
                :rules="mobileNumberRules" 
              />
            </v-col>
          </v-layout>

            <v-checkbox 
              v-model="showReferralCodeCombo" 
              :label="`${$t('have_referral_code')}`" 
            />

          <v-text-field 
            v-if="showReferralCodeCombo == true"
            v-model="user.referralCodeCombo"
            :label="$t('referral_code')"
            clearable 
            :rules="referralCodeRules">
          </v-text-field>

            <v-btn class="ma-2" color="primary ma-1" @click="navigateStep1()">{{ $t('previous') }}</v-btn>
            <v-btn color="primary ma-1" @click="showConfirmDialog()">{{ $t('continue') }}</v-btn>
        
        </v-form>
      </v-card>
      </v-stepper-content>

      <v-stepper-step :complete="currentStep > 2" step="2">{{ $t('verification') }}</v-stepper-step>

      <v-stepper-content step="2" height="auto">
        {{ this.$t('provide_otp') }}
        <v-alert :value="true" type="error" v-if="errorMessage.length > 0">{{ errorMessage }}</v-alert>
        <v-form ref="step3">
          <v-text-field type="number" v-model="user.otp" :label="$t('otp')" clearable :rules="otpCodeRules">
          </v-text-field>
          <v-btn color="primary" @click="submitToken()">{{ $t('continue') }}</v-btn>
        </v-form>
      </v-stepper-content>

      <v-stepper-step step="3">{{ $t('confirmation') }}</v-stepper-step>
      <v-stepper-content step="3">{{ $t('successful_registration') }}</v-stepper-content>
    </v-stepper>

    <v-dialog v-model="confirmDialog" fullscreen hide-overlay transition="dialog-bottom-transition">
      <v-card>
        <v-card-title style="word-break: break-word">{{ $t('confirm_account_details') }}</v-card-title>
        <v-card-text class="subtitle-1">
          {{ $t('confirm_account_details_info') }}


          <div class="pt-2">
            <p>{{ $t('name') }}<br><b>{{ user.name }}</b></p>
            <p>{{ $t('email') }}<br><b>{{ user.email }}</b></p>
            <p>{{ $t('mobile_number') }}<br><b>{{ user.countryCode }}{{ user.mobileNumber }}</b></p>
            <p v-if="showReferralCodeCombo">{{ $t('referral_code') }}<br><b>{{ user.referralCodeCombo }}</b></p>
          </div>
        </v-card-text>

        <v-card-actions>
          <v-spacer></v-spacer>

          <v-btn color="primary darken-1" text @click="confirmDialog = false">
            {{ $t('cancel') }}
          </v-btn>

          <v-btn color="primary darken-1" text @click="navigateStep3()" :disabled="disabledStep3">
            {{ $t('continue') }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-card>
</template>

<script>
import { DataService } from "../services/DataService";

const dataService = new DataService();

export default {
  computed: {
    mobileNumberValidationRules() {
      return this.mobileNumberRules;
    }
  },
  data() {
    return {
      confirmDialog: false,
      disabledStep3: false,
      currentStep: 0,
      errorMessage: "",
      mobileNumberRules: [
        value => !!value || `${this.$t("mobile_number")} ${this.$t('required')}`
      ],
      otpCodeRules: [
        value => !!value || `${this.$t('otp')} ${this.$t('required')}`,
        value => (value || "").length == 6 || this.$t("six_characters")
      ],
      referralCodeRules: [
        value => !!value || `${this.$t('referral_code')} ${this.$t('required')}`,
        value => /^[^\W_]{6,7}$/.test(value) || this.$t("code_invalid")
      ],
      selectedCountry: {},
      showReferralCodeCombo: false,
      user: {},
      valid: true,
      country: {
        title: this.$t("country"),
        required_message: `${this.$t('country')} ${this.$t('required')}`,
        answer: null,
        shared: true,
        disabled: true,
        options: []
      },
      keysMapping: {
        "Country": "country"
      },
    };
    
  },


  methods: {

    async navigateStep1() {
      this.errorMessage = "";
      this.currentStep = 0;
    },

    async navigateStep2() {

      let v = await this.$refs.step1.validate();

      this.errorMessage = "";
      if (v) {
        this.selectedCountry = this.country.options.filter(c => c.title === this.country.answer)[0];
        this.user.countryCode = this.selectedCountry.code;
        this.mobileNumberRules = [
          value => !!value || `${this.$t("mobile_number")} ${this.$t('required')}`,
          value => (value || "").length >= this.selectedCountry.min || this.$t("min") + this.selectedCountry.min + this.$t("characters"),
          value => (value || "").length <= this.selectedCountry.max || this.$t("max") + this.selectedCountry.max + this.$t("characters")
        ];

        this.currentStep = 2;
      }
    },

    async navigateStep3() {
      this.confirmDialog = false;
      this.disabledStep3 = true;
      this.$vuetify.goTo(0);

      let v = await this.$refs.step1.validate();
      this.errorMessage = "";
      let hasError = false;

      if (v) {
        // check if the country has some rules for key-in mobile number
        if (undefined != this.selectedCountry.failIfStartsWith) {
          if (this.user.mobileNumber.startsWith(this.selectedCountry.failIfStartsWith)) {
            hasError = true;

            this.errorMessage = this.$t("mobile_number_cannot") + this.selectedCountry.failIfStartsWith
          }
        }

        if (hasError) {
          console.log('has error')
          this.disabledStep3 = false;
          return;
        }

        this.user.name = this.user.name.trim()
        this.user.mobileNumber = this.user.mobileNumber.trim()

          let toUploadDemographics = []

          for (let key of Object.keys(this.keysMapping)) {
            let localObject = this[this.keysMapping[key]]

            if (localObject.answer != undefined && localObject.answer != null && localObject.answer != "") {
              toUploadDemographics.push({
                title: key,
                answer: localObject.answer,
                shared: true
              })
            }
          }
     
          if (toUploadDemographics.length >= 1) {
            if (this.showReferralCodeCombo) {
              if (/^\d+$/.test(this.user.referralCodeCombo)) {
                this.user.projectReferralCode = this.user.referralCodeCombo
                this.user.referralCode = undefined
              } else {
                this.user.referralCode = this.user.referralCodeCombo
                this.user.projectReferralCode = undefined
              }
            }

            this.user.demographics = toUploadDemographics

            if (localStorage.hasRegistered == "true") {
              this.disabledStep3 = false;
              this.errorMessage = "This device has already been registered. Please avoid registering multiple accounts."
            } else {
              
              dataService.submitRegistration(this.user).then(() => {
                this.currentStep = 2;
                localStorage.hasRegistered = "true"
              }).catch(e => {
                this.disabledStep3 = false;
                this.errorMessage = e.response.data.message;
              });
            }
          }
        
      } else {
        this.disabledStep3 = false;
      }
    },

    async submitToken() {
      let v = await this.$refs.step3.validate();
      this.errorMessage = "";
      if (v) {
        dataService.submitRegistrationToken({
          email: this.user.email,
          otp: this.user.otp,
          referralCode: this.user.referralCode
        }).then(() => {
          this.currentStep = 3;
        }).catch(() => {
          this.errorMessage = this.$t('otp_not_match');
        });
      }
    },

    async showConfirmDialog() {
      let v = await this.$refs.step1.validate()

      if (v)
        this.confirmDialog = true
    },

  },
  mounted() {
    this.user.idfa = this.$route.query.idfa;
    this.user.email = this.$route.query.email;
    this.user.name = this.$route.query.name;
    dataService.getConfig("countries").then(r => {
      this.country.options = r;
    });
  },
};

</script>

<style scoped lang="css">

</style>